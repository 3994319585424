<template>
  <div>
    <div class="reports-listing-main-wrapper">
      <div class="report-listing-left-wrapper">
        <div class="reports-listing-table">
          <div class="reports-listing-report-name">Report Name</div>
          <div
            class="reports-listing-report-options-wrapper"
            style="border-bottom: none"
          >
            <div class="reports-listing-options-text">Dashboard Report</div>
            <div class="reports-listing-options-icon">
              <div><DownloadOutlined @click="onCLickDashboardLogo" /></div>
            </div>
          </div>
          <div
            class="reports-listing-report-options-wrapper"
            style="border-bottom: none"
          >
            <div class="reports-listing-options-text">Resume Review Report</div>
            <div class="reports-listing-options-icon">
              <div><DownloadOutlined @click="onClickResumeLogo" /></div>
            </div>
          </div>
          <div class="reports-listing-report-options-wrapper">
            <div class="reports-listing-options-text">Landscape Report</div>
            <div class="reports-listing-options-icon">
              <div><DownloadOutlined @click="onClickLandscapeLogo" /></div>
            </div>
          </div>
        </div>
      </div>
      <div class="report-listing-right-wrapper">
        <RightSideBar />
      </div>
    </div>
    <div>
      <a-modal
        class="filter-modal"
        title=""
        width="500px"
        v-model:visible="dashboardVisible"
        :closable="false"
        centered
        footer=""
        destroyOnClose
        style="padding: 10px"
      >
        <DashboardReport @onClickDashboardCancel="onClickDashboardCancel" />
      </a-modal>
    </div>
    <div>
      <a-modal
        class="filter-modal"
        title=""
        width="500px"
        v-model:visible="resumeVisible"
        :closable="false"
        centered
        footer=""
        destroyOnClose
        style="padding: 10px"
      >
        <ResumeReport @onClickResumeCancel="onClickResumeCancel" />
      </a-modal>
    </div>

    <div>
      <a-modal
        class="filter-modal"
        title=""
        width="500px"
        v-model:visible="landscapeVisible"
        :closable="false"
        centered
        footer=""
        destroyOnClose
        style="padding: 10px"
      >
        <LandscapeReport @onClickLandscapeCancel="onClickLandscapeCancel" />
      </a-modal>
    </div>
  </div>
</template>
<script>
import RightSideBar from "../../reports/components/rightSideBar.vue";
import { ref, reactive, toRefs, onMounted } from "vue";
import { DownloadOutlined } from "@ant-design/icons-vue";
import DashboardReport from "./DashboardReport.vue";
import ResumeReport from "./ResumeReport.vue";
import LandscapeReport from "./LandscapeReport.vue";

export default {
  components: {
    RightSideBar,
    DownloadOutlined,
    DashboardReport,
    ResumeReport,
    LandscapeReport,
  },
  setup() {
    const dashboardVisible = ref(false);
    const resumeVisible = ref(false);
    const landscapeVisible = ref(false);

    const onCLickDashboardLogo = () => {
      dashboardVisible.value = true;
    };
    const onClickDashboardCancel = (value) => {
      dashboardVisible.value = value;
    };
    const onClickResumeLogo = () => {
      resumeVisible.value = true;
    };
    const onClickResumeCancel = (value) => {
      resumeVisible.value = value;
    };
    const onClickLandscapeLogo = () => {
      landscapeVisible.value = true;
    };
    const onClickLandscapeCancel = (value) => {
      landscapeVisible.value = value;
    };

    return {
      dashboardVisible,
      onCLickDashboardLogo,
      onClickDashboardCancel,
      resumeVisible,
      onClickResumeLogo,
      onClickResumeCancel,
      onClickLandscapeLogo,
      onClickLandscapeCancel,
      landscapeVisible,
    };
  },
};
</script>


<style lang="scss">
@import "../styles/reportsListing.scss";
</style>