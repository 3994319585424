<template>
  <div class="reports-right-action-bar-container">
    <div class="search-container">
      <a-input-search />
    </div>

    <div class="toggle-container">
      <div>
        <div class="parent-div">
          <div class="planner-div-on" id="action-btn">
            <a-button type="link" style="color: #4f4cff"> Action </a-button>
          </div>
          <div class="planner-div-off" id="recommendation-btn">
            <a-button type="link">
              <span style="margin-top: 0.1rem"></span>
            </a-button>
          </div>
        </div>
      </div>
    </div>
    <div class="menu-container">
      <div class="reports-workflow-text">Workflow</div>
      <a-menu mode="inline" :style="{ height: '100%', borderRight: 0 }">
        <a-sub-menu key="vacancy">
          <template #title>
            <span> Vacancy </span>
          </template>
          <a-menu-item key="create_vacancy" @click="createVacancy"
            >Create Vacancy</a-menu-item
          >
        </a-sub-menu>
        <a-sub-menu key="candidate">
          <template #title>
            <span> Candidate </span>
          </template>
          <a-menu-item key="create_candidate" @click="createCandidate"
            >Create Candidate</a-menu-item
          >
        </a-sub-menu>
        <a-sub-menu key="sub3">
          <template #title>
            <span> Clients </span>
          </template>
          <a-menu-item key="create_company" @click="createAccount"
            >Create Company</a-menu-item
          >
          <a-menu-item key="create_client_profile" @click="createClientPerson"
            >Create Client Person</a-menu-item
          >
        </a-sub-menu>
      </a-menu>
    </div>
    <a-divider class="reports-side-bar-divider" />
    <div class="action-section">
      <div>
        <p>Data</p>
      </div>
      <div class="action-sub-section">
        <div>Export</div>
      </div>
    </div>
    <footer>
      <a-divider class="reports-side-bar-divider" />
      <div class="reports-footer-wrapper">
        <div class="reports-calendar-icon"><CalendarOutlined /></div>
        <div class="reports-mail-icon"><MailOutlined /></div>
      </div>
    </footer>
  </div>
</template>
<script>
import { CalendarOutlined, MailOutlined } from "@ant-design/icons-vue";
import { ref } from "vue";
// import { useRouter } from "vue-router";

export default {
  components: { CalendarOutlined, MailOutlined },
  setup(props) {
    // const router = useRouter();

    // const createCandidate = () => {
    //   router.push(`/add-candidate`);
    // };

    // const createVacancy = () => {
    //   router.push(`/vacancy/create-new-vacancy`);
    // };
    // const createAccount = () => {
    //   router.push(`/add-account`);
    // };
    // const createClientPerson = () => {
    //   router.push(`/create-client-person`);
    // };

    return {
      //   createCandidate,
      //   createVacancy,
      //   createAccount,
      //   createClientPerson,
    };
  },
};
</script>
<style></style>
<style lang="scss">
@import "../styles/rightSideBar.scss";
</style>
