<template>
  <div>
    <div class="reports-export-common-text">Export</div>
    <div class="export-dashboard-reports">
      <div class="export-title-text">
        Financial Year<span style="color: red">*</span>
      </div>
      <div>
        <a-date-picker
          v-model:value="year"
          placeholer="Select Year"
          style="padding-top: 0.65rem; margin-right: 1rem; width: 100%;"
          mode="year"
          format="YYYY"
        ></a-date-picker>
      </div>
      <div class="dashboard-cancel-and-export-button-wrapper">
        <div class="dashboard-cancel-button" @click="onClickDashboardCancel">
          Cancel
        </div>
        <div class="dashboard-export-btn">
          <a :href="hrefData" download>
            <a-button type="primary" @click="changeYear">Export xls</a-button></a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import moment from "moment";
import Services from "@/services/apis";

export default {
  setup(props, context) {
    let hrefData = ref("");
    let year = ref(); 
    const changeYear = () => {
      let payload = {
        year: year.value,
      };

      Services.dashboardReports(payload)
        .then((response) => {
          const { data } = response;
          hrefData.value = data.link;
          console.log("hrefData :>> ", hrefData.value);

          onClickDashboardCancel();
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const onClickDashboardCancel = () => {
      context.emit("onClickDashboardCancel", false);
    };

    const onClickDOwnload = () => {
      context.emit("onClickDashboardCancel", false);
    };
    return {
      onClickDashboardCancel,
      year,
      changeYear,
      moment,
      hrefData,
      onClickDOwnload,
    };
  },
};
</script>


<style lang="scss">
@import "../styles/dashboardReport.scss";
</style>