<template>
  <div>
    <div class="reports-export-common-text">Export</div>

    <div class="resume-review-reports">
      <div class="date-with-filtert-text">
        <div class="resume-review-date-text">Date</div>
        <div class="resume-review-reset-filter-text" @click="resetFilters">
          Reset Filters
        </div>
      </div>
      <div class="reports-date-picker-wrapper">
        <div style="margin-right: 30px">
          <a-date-picker
            v-model:value="modelRef.fromDate"
            class="reports-date-picker"
            placeholder="From Date"
          />
        </div>
        <div>
          <a-date-picker
            v-model:value="modelRef.toDate"
            class="reports-date-picker"
            placeholder="To Date"
          />
        </div>
      </div>
      <div class="export-title-text">Vertical</div>
      <div>
        <a-select
          show-search
          placeholder="Select"
          class="dashboard-selecte-input"
          style="width: 100%"
          v-model:value="modelRef.verticals"
          :filter-option="false"
          :options="vertical_tags_options"
          @search="fetchVerticalData"
        >
        </a-select>
      </div>
       <div class="report-resume-checkbox-wrapper">
        <div class="resume-first-checkbox-group">
          <div :style="{ borderBottom: '1px solid #E9E9E9' }">
            <a-checkbox
              v-model:value="checkAllMonth"
              :indeterminate="indeterminate"
              @change="onCheckAllMonthChange"
            >
              Monthwise
            </a-checkbox>
          </div>
          <br />
          <a-checkbox-group
            v-model:value="checkedListMonth"
            :options="monthWise"
          />
        </div>
        <div class="resume-second-checkbox-group">
          <div :style="{ borderBottom: '1px solid #E9E9E9' }">
            <a-checkbox
              v-model:value="levelWise"
              :indeterminate="indeterminate"
              @change="onCheckAllLevelWise"
            >
              Levelwise
            </a-checkbox>
          </div>
          <br />
          <a-checkbox-group
            v-model:value="checkedListLevel"
            :options="levelWise"
          />
        </div>
      </div>
      <div class="dashboard-cancel-and-export-button-wrapper">
        <div class="dashboard-cancel-button" @click="onClickResumeCancel">
          Cancel
        </div>
        <div class="dashboard-export-btn">
          <a-button type="primary" @click="resumeReports">Export xls</a-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, reactive, watch, toRefs, onMounted } from "vue";
import moment from "moment";
import Services from "@/services/apis";

export default {
  setup(props, context) {
    let resumeReviewhrefData = ref("");
    let vertical_tags_options = ref("");

    const monthWise = ["REVIEWER", "PA", "LEVEL"];
    const levelWise = ["REVIEWER", "PA", "LEVEL"];

    const onClickResumeCancel = () => {
      context.emit("onClickResumeCancel", false);
    };
   
     const onCheckAllMonthChange = e => {
      Object.assign(state, {
        checkedListMonth: e.target.checked ? monthWise : [],
        indeterminate: false,
      });
    };

    const state = reactive({
      indeterminate: false,
      // indeter: false,indeterminate: true,
      checkAllMonth: false,
      // checkAllLevel: false,
      checkedListMonth: [],
      // checkedListLevel: [],
    });

    const modelRef = reactive({
      fromDate: "",
      toDate: "",
      verticals: [],
      monthWise: [],
      levelWise: [],
      checkedListMonth: [],
      checkedListLevel: [],
    });
    // const onCheckAllLevelWise = (e) => {
    //   Object.assign(state, {
    //     checkedListLevel: e.target.checked ? levelWise : [],
    //     indeterminate: false,
    //   });
    // };

   const resetFilters = () => {
      modelRef.fromDate= "";
      modelRef.toDate= "";
      modelRef.verticals=[];
      modelRef.monthWise= [];
      modelRef.levelWise= [];
      modelRef.checkedListMonth= "";
      modelRef.checkedListLevel= "";
   }

    const fetchVerticalData = (value) => {
      let payload = { q: value, mode: "AUTOCOMPLETE" };
      Services.getVerticalMasterDetail(payload)
        .then((response) => {
          const { data } = response;
          vertical_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {});
    };

    const resumeReports = () => {
      let payload = {
        fromDate: modelRef.fromDate,
        toDate: modelRef.toDate,
        verticals: [modelRef.verticals],
        monthWise: state.checkedListMonth,
        levelWise: state.checkedListLevel,
      };

      Services.resumeReviewReports(payload)
        .then((response) => {
          const { data } = response;
          resumeReviewhrefData.value = data.link;
          console.log("resumeReviewhrefData :>> ", resumeReviewhrefData.value);
          onClickResumeCancel();
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    watch(
      () => state.checkedListMonth,
      // () => state.checkedListLevel,
      val => {
        state.indeterminate = !!val.length && val.length < monthWise.length;
        state.checkAllMonth = val.length === monthWise.length;
      },
      // (val) => {
      //   state.indeter = !!val.length && val.length < monthWise.length;
      //   state.checkAllMonth = val.length === monthWise.length;
      // },
      // (val) => {
      //   state.indeterminate = !!val.length && val.length < levelWise.length;
      //   state.checkAllLevel = val.length === levelWise.length;
      // }
    );

    onMounted(() => {
      fetchVerticalData();
    });

    return {
      onClickResumeCancel,
      ...toRefs(state),
      monthWise,
      levelWise,
      modelRef,
      resumeReviewhrefData,
      resumeReports,
      moment,
      vertical_tags_options,
      fetchVerticalData,
      resetFilters,
      onCheckAllMonthChange
        
    };
  },
};
</script>


<style lang="scss">
@import "../styles/dashboardReport.scss";
</style>