<template>
  <div>
    <div class="reports-export-common-text">Export</div>
    <div class="landscape-reports">
      <div class="date-with-filtert-text">
        <div class="resume-review-date-text">Company (Work Exp)</div>
        <div class="resume-review-reset-filter-text" @click="resetFilters">Reset Filters</div>
      </div>
      <div class="reports-company-input-wrapper">
        <a-input v-model:value="modelRef.workExperience" placeholder="Enter" />
      </div>
      <!-- <div class="export-title-text">Vertical</div> -->
      <div style="display: flex">
        <div style="margin-right: 30px">
          <div class="reports-input-texts">Client Company</div>
          <div class="client-and-vacancy-input-wrapper">
            <a-select
              show-search
              v-model:value="modelRef.companyId"
              style="width: 100%"
              placeholder="Select Account"
              :options="company_options"
              @search="getCompanyName"
              @change="onSelectAccountChange"
              :filter-option="false"
            ></a-select>
          </div>
        </div>
        <div>
          <div class="reports-input-texts">Vacancy</div>
          <div class="client-and-vacancy-input-wrapper">
            <a-input v-model:value="modelRef.vacancy" placeholder="Enter" />
          </div>
        </div>
      </div>
      <div>
        <a-select
          v-model:value="modelRef.practiceAreas"
          mode="tags"
          style="width: 100%"
          placeholder="Select PA"
          :options="practice_area_tags_options"
          @search="fetchPracticeAreaData"
          class="export-resume-select-tags"
        >
        </a-select>
      </div>
      <div>
        <a-select
          v-model:value="modelRef.subPracticeAreas"
          mode="tags"
          style="width: 100%"
          placeholder="Select SPA"
          :options="sub_practice_area_tags_options"
          @change="handleChange"
          @search="fetchSubPracticeAreaData"
          class="export-resume-select-tags"
        >
        </a-select>
      </div>
      <div>
        <a-select
          v-model:value="modelRef.categories"
          mode="tags"
          style="width: 100%"
          placeholder="Select Category"
          :options="options"
          class="export-resume-select-tags"
        >
        </a-select>
      </div>
      <div class="dashboard-cancel-and-export-button-wrapper">
        <div class="dashboard-cancel-button" @click="onClickLandscapeCancel">
          Cancel
        </div>
        <div class="dashboard-export-btn">
          <a :href="landscapeReporthrefData" download>
            <a-button type="primary" @click="landscapeReports"
              >Export xls</a-button
            >
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, reactive, onMounted } from "vue";
import Services from "@/services/apis";

export default {
  setup(props, context) {
    let landscapeReporthrefData = ref("");
    let practice_area_tags_options = ref([]);
    let sub_practice_area_tags_options = ref([]);
    let company_options = ref([]);

    const modelRef = reactive({
      workExperience: "",
      companyId: "",
      vacancy: "",
      practiceAreas: [],
      subPracticeAreas: [],
      categories: [],
    });

    const onClickLandscapeCancel = () => {
      context.emit("onClickLandscapeCancel", false);
    };

    const landscapeReports = (e, value) => {
      console.log("e :>> ", value);
      let payload = {
        workExperience: modelRef.workExperience,
        companyId: modelRef.companyId,
        vacancy: modelRef.vacancy,
        practiceAreas: modelRef.practiceAreas,
        subPracticeAreas: modelRef.subPracticeAreas,
        categories: modelRef.categories,
      };

      Services.postLandscapeReports(payload)
        .then((response) => {
          const { data } = response;
          landscapeReporthrefData.value = data.link;
          console.log(
            "landscapeReporthrefData :>> ",
            landscapeReporthrefData.value
          );

          onClickLandscapeCancel();
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const fetchPracticeAreaData = (value) => {
      let payload = {};
      payload = { q: value, types: ["PRACTICE_AREA"] };
      Services.getPracticeAreaDetail(payload)
        .then((response) => {
          const { data } = response;

          practice_area_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {});
    };
    const fetchSubPracticeAreaData = (value) => {
      let payload = {};
      payload = { q: value, types: ["SUB_PRACTICE_AREA"] };
      Services.getPracticeAreaDetail(payload)
        .then((response) => {
          const { data } = response;
          // let subPracticeAreData = [];
          // let subPracticeAreaOptions = data[0].vacancies.filter(
          //   (vacancy) => vacancy.account.id === accountId.value
          // );
          // subPracticeAreaOptions.map((x) => {
          //   subPracticeAreData.push({
          //     value: x.vacancy.id,
          //     label: x.vacancy.title,
          //   });
          // });

          //  Object.assign(sub_practice_area_tags_options.value, listingData);

          sub_practice_area_tags_options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {});
    };

      function onSelectAccountChange(value) {
      accountId.value = value;
      store.dispatch(
        "candidateStore/candidateProfileSelectedVacancy",
        modelRef.accountName
      );
    }

    const getCompanyName = (value) => {
      let payload = {};
      console.log("value` ------ :>> ", value);
      let q = "";
      payload = { q: value, mode: "AUTOCOMPLETE" };
      Services.getAccountListing(payload)
        .then((response) => {
          const { data } = response;
          let tempData = [];
          data.map((x) => {
            tempData.push({
              value: x.account.id,
              label: x.account.name,
            });
          });
          Object.assign(company_options.value, tempData);
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    };

    const resetFilters = () => {
      modelRef.workExperience= "";
      modelRef.companyId= "";
      modelRef.vacancy= "";
      modelRef.practiceAreas= [];
      modelRef.subPracticeAreas= [];
      modelRef.categories= [];
    }

    onMounted(() => {
      fetchPracticeAreaData();
      fetchSubPracticeAreaData();
      getCompanyName();
    });
    return {
      onClickLandscapeCancel,
      landscapeReporthrefData,
      landscapeReports,
      modelRef,
      practice_area_tags_options,
      sub_practice_area_tags_options,
      company_options,
      getCompanyName,
      onSelectAccountChange,
      resetFilters
    };
  },
};
</script>


<style lang="scss">
@import "../styles/dashboardReport.scss";
</style>